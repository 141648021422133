
import { defineComponent } from 'vue'
import TopFilter from '@/components/layout/topFilter/TopFilter.vue'
import TopFilterButton from '@/components/layout/topFilter/TopFilterButton.vue'
import TmDropdownDownload from '@/components/shared/dropdowns/TmDropdownDownload.vue'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  name: 'ContactsUnsubscribedFilter',
  components: {
    TopFilterButton,
    TopFilter,
    TmDropdownDownload,
  },
  setup() {
    const { openModal } = useModals()

    return {
      openModal,
    }
  },
})
