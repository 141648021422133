import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_top_filter_button = _resolveComponent("top-filter-button")!
  const _component_tm_dropdown_download = _resolveComponent("tm-dropdown-download")!
  const _component_top_filter = _resolveComponent("top-filter")!

  return (_openBlock(), _createBlock(_component_top_filter, null, {
    "filter-line-left-default": _withCtx(() => [
      _createVNode(_component_top_filter_button, {
        text: "Settings",
        icon: "settings",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openModal('contactsUnsubscribedSettings')))
      }),
      _createVNode(_component_top_filter_button, {
        text: "Import",
        icon: "tmi-upload",
        to: { name: 'base.contacts.importFlow' }
      }, null, 8, ["to"]),
      _createVNode(_component_tm_dropdown_download, { size: "large" })
    ]),
    _: 1
  }))
}