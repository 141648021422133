
import type { PropType } from 'vue'
import { defineComponent, ref, toRefs } from 'vue'
import TmTableActionButton from '@/components/shared/table/TmTableActionButton.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import type { ContactUnsubscribed } from '../../../../definitions/contacts/unsubscribed/types'
import TmButton from '@/components/shared/TmButton.vue'
import useContactsOpenModal from '@/compositions/contacts/useContactsOpenModal'

export default defineComponent({
  name: 'ContactsUnsubscribedDropdown',
  components: {
    TmButton,
    TmDropdown,
    TmTableActionButton,
    TmDropdownItem,
  },
  props: {
    contact: {
      type: Object as PropType<ContactUnsubscribed>,
      required: true,
    },
  },
  setup(props) {
    const { openResubscribeContactModal } = useContactsOpenModal()
    const { contact } = toRefs(props)
    const block = ref(contact.value.allowIncomingMessage)

    return {
      block,
      openResubscribeContactModal,
    }
  },
})
