
import { computed, defineComponent, ref } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import ContactsUnsubscribedTable from '@/components/pages/contacts/unsubscribed/ContactsUnsubscribedTable.vue'
import ContactsUnsubscribedFilter from '@/components/pages/contacts/unsubscribed/ContactsUnsubscribedFilter.vue'
import { getTableData } from '@/services/tableService'
import type { TableHeaders } from '@/definitions/shared/types'
import { formatDate } from '@/services/dateTimeService'
import type { ContactUnsubscribed } from '@/definitions/contacts/unsubscribed/types'
import ContactsUnsubscribedEmpty from '@/components/pages/contacts/unsubscribed/ContactsUnsubscribedEmpty.vue'
import { useModes } from '@/compositions/modes'

export default defineComponent({
  components: {
    ContactsUnsubscribedEmpty,
    PageContent,
    ContactsUnsubscribedTable,
    ContactsUnsubscribedFilter,
  },
  props: {
    title: {
      type: String,
    },
  },
  setup() {
    const { isEmptyMode } = useModes()
    const search = ref('')
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Full name', value: 'person-slot' },
      { text: 'Phone', value: 'phone-slot' },
      { text: 'Reason', value: 'reason-slot' },
      { text: 'Unsubscribe text', value: 'unsubscribeText' },
      { text: 'Date unsubscribed', value: 'dateUnsubscribed', format: (val: string) => formatDate(val) },
    ])

    const tableItems = ref<ContactUnsubscribed[]>(getTableData('contactsUnsubscribed'))
    const filteredTableItems = computed<ContactUnsubscribed[]>(() => {
      return tableItems.value.filter((item) => item.person.fullName.toLowerCase().includes(search.value.toLowerCase()))
    })

    return {
      isEmptyMode,
      search,
      tableHeaders,
      tableItems,
      filteredTableItems,
    }
  },
})
