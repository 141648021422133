import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_contacts_unsubscribed_filter = _resolveComponent("contacts-unsubscribed-filter")!
  const _component_contacts_unsubscribed_table = _resolveComponent("contacts-unsubscribed-table")!
  const _component_contacts_unsubscribed_empty = _resolveComponent("contacts-unsubscribed-empty")!
  const _component_page_content = _resolveComponent("page-content")!

  return (_openBlock(), _createBlock(_component_page_content, { title: _ctx.title }, {
    "filter-bar-slot": _withCtx(() => [
      _renderSlot(_ctx.$slots, "filter-bar-slot")
    ]),
    "page-content-top-right": _withCtx(() => [
      _renderSlot(_ctx.$slots, "page-content-top-right")
    ]),
    default: _withCtx(() => [
      (!_ctx.isEmptyMode)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_contacts_unsubscribed_filter, {
              search: _ctx.search,
              "onUpdate:search": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event))
            }, null, 8, ["search"]),
            _createVNode(_component_contacts_unsubscribed_table, {
              headers: _ctx.tableHeaders,
              items: _ctx.filteredTableItems,
              "item-key": "id",
              "items-name": "contacts"
            }, null, 8, ["headers", "items"])
          ], 64))
        : (_openBlock(), _createBlock(_component_contacts_unsubscribed_empty, { key: 1 }))
    ]),
    _: 3
  }, 8, ["title"]))
}