import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_empty_state = _resolveComponent("tm-empty-state")!

  return (_openBlock(), _createBlock(_component_tm_empty_state, {
    icon: "do_not_disturb_on",
    title: "You have no unsubscribed contacts yet",
    description: "When someone opts out from your messages, these numbers will be displayed here. ",
    "background-lift": ""
  }))
}