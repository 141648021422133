
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmPerson from '@/components/shared/TmPerson.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import ContactsUnsubscribedDropdown from '@/components/pages/contacts/unsubscribed/ContactsUnsubscribedDropdown.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import TmCountry from '@/components/shared/TmCountry.vue'

export default defineComponent({
  name: 'ContactsUnsubscribedTable',
  components: {
    TmCountry,
    TmStatus,
    TmTable,
    TmPerson,
    ContactsUnsubscribedDropdown,
  },
  props: {
    headers: {
      type: Array as PropType<TableHeaders[]>,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    itemsName: {
      type: String,
      required: true,
    },
    itemKey: {
      type: String,
      default: 'id',
    },
    showSelection: {
      type: Boolean,
      default: false,
    },
  },
})
